import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { usePortalFramework } from "@sal/portal";
import logo from "./../images/salhr-logo-sm.png"; //TODO-AG change to salhr-logo.png when latest portal is up

const Container = styled("div")(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		width: 300,
	},
	[theme.breakpoints.up("sm")]: {
		width: 350,
	},
	[theme.breakpoints.up("lg")]: {
		width: 400,
	},
}));



export const ForgotPassword = (): JSX.Element => {
	const theme = useTheme();

	//const backgroundColor = theme.palette.background.default;

	return (
		<Grid 
			container
			justifyContent="center"
			alignItems="center"
			style={{ minHeight: "100vh" }}
		>
			<Box sx={theme.portalFramework.pages.login.backgroundContainer.sx} />
			<Container>
				<Paper elevation={3} sx={{ padding: theme.spacing(2) }}>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						spacing={4}
						style={{ paddingBottom: "1rem" }}
					>
						<Grid item>
							<Box
								sx={{
									paddingTop: "1rem"
								}}
							>
								<img
									style={{ maxWidth: "100%", maxHeight: "20rem" }}
									src={logo}
									alt="Logo"
								/>
							</Box>
						</Grid>
						<Grid item>
							<Typography variant="h4" align="center">
								Forgot Password
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1" align="center">
								Passwords are synced from SALNet, so please reset your password there.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Link variant="body1" href="#/Login" sx={{ color: "inherit" }}>
								Return to log in
							</Link>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Grid>
	);
};
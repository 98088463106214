import { createRoot } from "react-dom/client";

import { MicrosoftSsoSettings, PortalFramework, Settings } from "@sal/portal";
// import { Theme as PortalFrameworkTheme } from "@sal/portal-theme-default";
import { Theme as PortalFrameworkTheme } from "@sal/portal-theme-swirl";
import Routes from "./Routes";
import "./styles/css.css";
import logo from "./images/salhr-logo-sm.png"; //TODO-AG change to salhr-logo.png when latest portal is up
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey("31b94373a1a30d969c29cb3d1f67aab1T1JERVI6NDMwNDUsRVhQSVJZPTE2ODMyNDc1MzcwMDAsS0VZVkVSU0lPTj0x");

const microsoftSsoSettings: MicrosoftSsoSettings = {
	enableSso: true,
	clientID: process.env.MICROSOFT_SSO_SETTINGS_CLIENT_ID!,
	authority: process.env.MICROSOFT_SSO_SETTINGS_AUTHORITY!,
	redirectUrl: process.env.MICROSOFT_SSO_SETTINGS_REDIRECT_URL!,
	scopes: ["user.read", "profile"]
};

const pfSettings: Settings = {
	logoUrl: logo,
	defaultColorMode: "light",
	loginTitle: "Login To SAL HR 2.0",
	microsoftSsoSettings: microsoftSsoSettings
};

document.title = "SALHR - Login";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<PortalFramework settings={pfSettings}>
		<PortalFrameworkTheme>
			<Routes />
		</PortalFrameworkTheme>
	</PortalFramework>
);

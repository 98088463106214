import { Suspense, lazy } from "react";
import { HashRouter as Router, Routes as ReactRouterRoutes, Route} from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import { AuthenticatedRoute, Login, ResetPassword, NotFound } from "@sal/portal";
import { useAuth } from "@sal/authentication.react";
import { ForgotPassword } from "./components/ForgotPassword";

const PageLayout = lazy(() => import("./PageLayout"));
const Home = lazy(() => import("./pages/home/Home"));

export default function Routes() {
	const auth = useAuth();
	const menuWidth = 450;
	

	return (
		<Router>
			<Suspense fallback={<Backdrop invisible open><CircularProgress /></Backdrop>}>
				{/*{auth.isLoggedIn ? <Menu width={menuWidth} /> : null}*/}
				<ReactRouterRoutes>
					
					<Route element={<AuthenticatedRoute />}>
						<Route element={<PageLayout menuWidth={menuWidth} />}>
							<Route path="/" element={<Home  />} />
						</Route>
					</Route>


					{/* PORTAL FRAMEWORK PAGES*/}
					<Route path="/Login" element={<Login />} />
					<Route path="/ForgotPassword" element={<ForgotPassword />} />
					<Route path="/ResetPassword/:passwordResetToken" element={<ResetPassword />} />
					<Route path="/NotFound" element={<NotFound />} />
					<Route path="*" element={<NotFound />} />
					{/* /PORTAL FRAMEWORK PAGES*/}
				</ReactRouterRoutes>
			</Suspense>
		</Router>
	);
}

